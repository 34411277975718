import React from "react";
import { graphql, useStaticQuery } from 'gatsby';

import Layout from '../components/layout';
import SubscribeForm from '../components/SubscribeForm';
import Head from '../components/head';

import {
    BlogContainer,
    Post,
    PostLink,
    PostFooter,
    Image
} from '../Styles/Blog.style';

const Blog = () => {
    const data = useStaticQuery(graphql`
        query {   
            allMarkdownRemark {
                edges {
                    node {
                        frontmatter{
                            id
                            title
                            subtitle
                            author
                            date
                            img_url
                        }
                        html
                        excerpt
                        fields {
                            slug
                        }
                    }
                }
            }
        }
    `);

    return (
        <Layout>
            <Head title="Blog" />
            <BlogContainer>
                <h1>Blog</h1>
                <div>
                    {data.allMarkdownRemark.edges.map(edge => { 
                        return (
                            <Post key={edge.node.frontmatter.id}>    
                                <PostLink to={`/blog/${edge.node.fields.slug}`}>                         
                                    <h2>{edge.node.frontmatter.title}</h2>
                                    <h3>{edge.node.frontmatter.subtitle}</h3>
                                    <Image src={edge.node.frontmatter.img_url} alt={edge.node.frontmatter.title}/>  
                                </PostLink>       
                                <p>{edge.node.excerpt}</p>                           
                                <PostFooter>
                                    <span>by @{edge.node.frontmatter.author}</span>
                                    <span>{edge.node.frontmatter.date}</span>
                                </PostFooter>                               
                            </Post>
                        )
                    })}
                    <SubscribeForm />
                </div>
            </BlogContainer>
        </Layout>
    );
}

export default Blog;