import styled from "styled-components"
import { Link } from 'gatsby'

export const BlogContainer = styled.div`
    width: 60%;
    margin: 0 auto;
    
    h1 {
        text-align: center;
        margin-bottom: 50px;
        font-size: 50px;
    }

    @media (max-width: 800px) {
        width: 100%; 
    }
`;

export const Post = styled.div`

    display: flex;
    flex-direction: column;
    margin: 30px;

    h2 {
        font-size: 1.5rem;
        margin-bottom: 0;
    }

    h3 {
        color: #666;
    }
`;

export const PostLink = styled(Link)`
    color: black;
    text-decoration: none;
`;

export const Image = styled.div`
    display: block;
    height: 300px;
    width: 100%;
    background-image: url(${props => props.src});
    background-size: cover;
    background-position: center;
    margin-bottom: 5px;
`;

export const PostFooter = styled.div`
    color: gray;
    margin: 10px 0;

    span {
        margin-right: 20px;
    }
`;
